import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from 'moment';
import { FormGroup, FormControl, Button, Grid, Row, Col, Navbar, NavItem, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./DataSourceList.css";

import OrgStackAPI from '../OrgStackAPI';


export default function DataSourceList(props) {
  const [dataSources, setDataSources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [query, setQuery] = useState("");

  useEffect(() => {
    async function onLoad() {
      if (!props.isAuthenticated) {
        return;
      }

      try {
        const dataSources = await loadDataSources();
        setDataSources(dataSources.data.results);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated]);

  function loadDataSources() {
    return OrgStackAPI.get("/v1/data_sources");
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>OrgStack</h1>
        <p>Verifiable data contracts</p>
      </div>
    );
  }

  function renderEmpty() {
    return (
      <div className="lander">
        <h1>No data sources yet</h1>
        <p><Link to="/data_sources/new">Create data sources</Link> to get started</p>
      </div>
    );
  }

  async function searchDataSources(query) {
    setQuery(query);
    const query_string = new URLSearchParams([["name", query]]).toString();

    try {
      setIsLoading(true);
      const data_sources_res = await OrgStackAPI.get(`/v1/data_sources/?${query_string}`);
      setDataSources(data_sources_res.data.results);
    } catch (e) {
      alert(e);
    }

    setIsLoading(false);
  }

  function validateSearch() {
    return true;
  }

  function renderDataSources() {
    return (
      !isLoading &&
      <>
        <Navbar id="datasource-action-navbar" bsStyle="navbar-transparent" fluid>
          <Nav>
            <LinkContainer to="/data_sources/new">
              <NavItem>{plusIcon}&nbsp;&nbsp;Add Data Source</NavItem>
            </LinkContainer>
          </Nav>
          <Navbar.Form pullRight>
            <FormGroup
              controlId="formBasicText"
              validationState={validateSearch()}
            >
              <FormControl
                type="text"
                value={query}
                placeholder="Search data sources"
                onChange={e => searchDataSources(e.target.value)}
              />
            </FormGroup>
          </Navbar.Form>
        </Navbar>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Version</th>
              <th scope="col">Subscribers</th>
              <th scope="col">Modified</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              dataSources.length < 1 ? renderEmpty() : dataSources.map((data_source, i) => (
                <tr>
                  <td>
                    <Link to={`/data_sources/${data_source.id}`}>{ data_source.name }</Link>
                  </td>
                  <td>{ data_source.version }</td>
                  <td>{ data_source.subscriber_count }</td>
                  <td>{ moment(data_source.updated).fromNow() }</td>
                  <td>
                    <Link to={`/data_sources/${data_source.id}/edit`}>Edit / Delete</Link>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </>
    )
  }

  const plusIcon = (
    <svg class="bi bi-plus" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z" clip-rule="evenodd"/>
      <path fill-rule="evenodd" d="M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z" clip-rule="evenodd"/>
    </svg>
  );

  return (
    <div className="DataSources">
      {props.isAuthenticated ? renderDataSources() : renderLander()}
    </div>
  );
}
