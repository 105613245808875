import React, { useState, useEffect } from 'react';
import { Panel } from 'react-bootstrap';
import moment from 'moment';

import OrgStackAPI from '../OrgStackAPI';


export default function NotificationList(props) {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!props.isAuthenticated) {
        return;
      }

      try {
        const notifications = await loadNotifications();
        setNotifications(notifications.data.results);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated]);

  function loadNotifications() {
    return OrgStackAPI.get("/v1/log_entries");
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>OrgStack</h1>
        <p>Verifiable data contracts</p>
      </div>
    );
  }

  function renderEmpty() {
    return (
      <div className="lander">
        <h1>No notifications yet</h1>
        <p>Add data sources and repositories to start recieving notifications</p>
      </div>
    );
  }

  function renderNotifications() {
     const action_displays = {
      "1": "created",
      "2": "updated",
      "3": "deleted"
    };

    const model_displays = {
      "datasource": "data source",
      "repoconfig": "repository"
    };

    // TODO: Add message for change details
    const rendered_notifications = notifications.length < 1 ? renderEmpty() : notifications.map((notification, i) => (
      <Panel>
        <Panel.Body>
          <div className="text-muted">{ moment(notification.action_time).fromNow() }</div>
          <div>
            <span className="text-info">
              { `${notification.user.first_name} ${notification.user.last_name}` }&nbsp;
            </span>
            { action_displays[notification.action_flag.toString()] }&nbsp;
            { model_displays[notification.content_type_model ] }&nbsp;
            <span className="text-info">
              { notification.object_repr }
            </span>
          </div>
        </Panel.Body>
      </Panel>
    ));

    return (
      !isLoading &&
      <div>{ rendered_notifications }</div>
    )
  }

  return (
    <div className="Notifications">
      {props.isAuthenticated ? renderNotifications() : renderLander()}
    </div>
  );
}
