import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";
import OrgStackAPI from "./OrgStackAPI";
import './App.css';


function App(props) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [active_tab, setActiveTab] = useState(1);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const authToken = localStorage.getItem('auth-token');
      if(authToken === null) {
        throw new Error('User is not authenticated');
      }
      OrgStackAPI.defaults.headers.common['Authorization'] = `Token ${authToken}`;
      userHasAuthenticated(true);
    }
    catch(e) {
      props.history.push("/login");
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    localStorage.clear()
    userHasAuthenticated(false);
    props.history.push("/login");
  }

  return (
    !isAuthenticating &&
    <div className="App container">
      {isAuthenticated &&
        <Navbar fluid collapseOnSelect bsStyle="navbar-transparent navbar-border-bottom">
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">OrgStack</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              <LinkContainer to="/docs">
                <NavItem>Docs</NavItem>
              </LinkContainer>
              <NavDropdown
               eventKey={3}
               title={<strong>1cph93@gmail.com</strong>}
               noCaret={true}
              >
                <LinkContainer to="/settings">
                  <MenuItem eventKey={3.1}>Settings</MenuItem>
                </LinkContainer>
                <MenuItem divider />
                <MenuItem eventKey={3.2} onClick={handleLogout}>Logout</MenuItem>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      }
      {isAuthenticated &&
        <Nav id="resource_nav" bsStyle="pills" activeKey={active_tab} onSelect={key => setActiveTab(key)}>
          <LinkContainer to="/data_sources">
            <NavItem eventKey={1}>Data Sources</NavItem>
          </LinkContainer>
          <LinkContainer to="/repositories">
            <NavItem eventKey={2}>Repositories</NavItem>
          </LinkContainer>
          <LinkContainer to="/notifications">
            <NavItem eventKey={3}>Notifications</NavItem>
          </LinkContainer>
        </Nav>
      }
      <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
    </div>
  );
}

export default withRouter(App);
