import React from "react";
import "./Docs.css";

export default function Docs(props) {
  return (
    <div className="Docs">
      <h2>Getting Started</h2>
      <p>Use this guide to start validating Data Sources in OrgStack</p>
      <ol>
        <li>
          In OrgStack:
          <ul>
            <li>Create a Data Source</li>
          </ul>
        </li>
        <li>
          In your code:
          <ul>
            <li>Create a file named <code>datadep.json</code> file in the root of your project</li>
            <li>Define <code>producing</code> section in <code>datadep.json</code> (Here's an example producing configuration)</li>
            <li>Create a generation script that can be used to verify your data source.  The output of this script should be on stdout</li>
          </ul>
        </li>
        <li>
          On your build server:
          <ul>
            <li>Install the OrgStack client (link), and make sure the script is executable</li>
            <li>Configure the client with <code>orgstack verify</code>, using your application credentials</li>
            <li>Run <code>orgstack verify</code> in your testing pipeline</li>
          </ul>
        </li>
        <li>
          View build results in OrgStack
        </li>
        <li>
          Start consuming the Data Source in other code repositories, using the "Install" instructions
        </li>
      </ol>
    </div>
  );
}
