import React, { useRef, useState, useEffect } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import OrgStackAPI from "../OrgStackAPI";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./EditDataSource.css";


export default function EditDataSource(props) {
  const [data_source, setDataSource] = useState(null);
  const [name, setName] = useState("");
  const [version, setVersion] = useState("");
  const [description, setDescription] = useState("");
  const [defined_in, setDefinedIn] = useState("");
  const [build_sequence, setBuildSequence] = useState(null);
  const [subscriber_count, setSubscriberCount] = useState(0);
  const [data_type, setDataType] = useState("");
  const [current_example_data_file_url, setCurrentExampleDataFileURL] = useState("");
  const example_data_file = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    function loadDataSource() {
      return OrgStackAPI.get(`/v1/data_sources/${props.match.params.id}`);
    }

    async function onLoad() {
      try {
        const res = await loadDataSource();
        setDataSource(res.data);

        const { name, version, description, data_schema, build_sequence, defined_in, subscriber_count, created, updated } = res.data;

        setName(name);
        setVersion(version);
        setDescription(description);
        setSubscriberCount(subscriber_count);
        setBuildSequence(build_sequence);
        setSubscriberCount(subscriber_count);

        if (data_schema !== null) {
          setDataType(data_schema.data_type);
          setCurrentExampleDataFileURL(data_schema.example_data_file);
          example_data_file.current = data_schema.example_data_file;
        }

      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function validateForm() {
    return name.length > 0 && version.length > 0 && description.length > 0;
  }

  // TODO: Pre-load selected file from S3, if possible
  function handleFileChange(event) {
    example_data_file.current = event.target.files[0];
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setIsLoading(true);

      const teams_res = await OrgStackAPI.get('/v1/teams/');
      const team = teams_res.data.results[0].url;

      let form_data = new FormData();
      form_data.append('name', name);
      form_data.append('version', version);
      form_data.append('description', description);
      form_data.append('team', team);
      form_data.append('data_type', data_type);
      form_data.append('example_data_file', example_data_file.current);

      const res = await OrgStackAPI.put(`/v1/data_sources/${data_source.id}/`, form_data);

      props.history.push(`/data_sources/${data_source.id}`);
    } catch (e) {
      setIsLoading(false);
      alert(e);
    }
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this Data Source?"
    );

    if (!confirmed) {
      return;
    }

    try {
      setIsDeleting(true);
      await OrgStackAPI.delete(`/v1/data_sources/${data_source.id}`);
      props.history.push("/data_sources");
    } catch (e) {
      setIsDeleting(false);
    }

  }

  return (
    <div className="DataSource">
      {data_source && (
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="name">
            <ControlLabel>Name</ControlLabel>
            <FormControl
              value={name}
              placeholder={name}
              componentClass="input"
              onChange={e => setName(e.target.value)}
            />
          </FormGroup>

          <FormGroup controlId="version">
            <ControlLabel>Version</ControlLabel>
            <FormControl
              value={version}
              placeholder={version}
              componentClass="input"
              onChange={e => setVersion(e.target.value)}
            />
          </FormGroup>

          <FormGroup controlId="data_type">
            <ControlLabel>Data Type</ControlLabel>
            <FormControl
              value={data_type}
              placeholder={data_type}
              componentClass="select"
              onChange={e => setDataType(e.target.value)}
            >
              <option value="json">JSON</option>
              <option value="csv">CSV</option>
              <option value="xml">XML</option>
            </FormControl>
          </FormGroup>

          <FormGroup controlId="description">
            <ControlLabel>Description</ControlLabel>
            <FormControl
              value={description}
              componentClass="textarea"
              onChange={e => setDescription(e.target.value)}
            />
          </FormGroup>

          <FormGroup controlId="example_data_file">
            <ControlLabel>Example Data File (Previously uploaded file is <a href={current_example_data_file_url}>here</a>)</ControlLabel>
            <FormControl onChange={handleFileChange} type="file" />
          </FormGroup>

          <LoaderButton
            block
            type="submit"
            bsSize="large"
            bsStyle="primary"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Save
          </LoaderButton>
          <LoaderButton
            block
            bsSize="large"
            bsStyle="danger"
            onClick={handleDelete}
            isLoading={isDeleting}
          >
            Delete
          </LoaderButton>
        </form>
      )}
    </div>
  );
}
