import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import NewDataSource from "./containers/NewDataSource";
import DataSourceList from "./containers/DataSourceList";
import EditDataSource from "./containers/EditDataSource";
import DataSource from "./containers/DataSource";
import NotificationList from "./containers/NotificationList";
import Docs from "./containers/Docs";


export default function Routes({ appProps }) {
  return (
    <Switch>
      <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />

      <AuthenticatedRoute path="/" exact component={Home} appProps={appProps} />
      <AuthenticatedRoute path="/data_sources" exact component={DataSourceList} appProps={appProps} />
      <AuthenticatedRoute path="/data_sources/new" exact component={NewDataSource} appProps={appProps} />
      <AuthenticatedRoute path="/data_sources/:id/edit" exact component={EditDataSource} appProps={appProps} />
      <AuthenticatedRoute path="/data_sources/:id" exact component={DataSource} appProps={appProps} />
      <AuthenticatedRoute path="/notifications" exact component={NotificationList} appProps={appProps} />
      <AuthenticatedRoute path="/docs" exact component={Docs} appProps={appProps} />

      { /* Catch all unmatched routes */ }
      <Route component={NotFound} />
    </Switch>
  );
}
