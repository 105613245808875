import React, { useState } from "react";
import { FormGroup, FormControl, ControlLabel, Panel } from "react-bootstrap";
import LoaderButton from '../components/LoaderButton';
import OrgStackAPI from '../OrgStackAPI';
import "./Login.css";
import logo from '../transparent_logo_cropped.png';


export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const res = await OrgStackAPI.post('api-token-auth/', {
        username: email,
        password: password
      });
      localStorage.setItem('auth-token', res.data.token);
      OrgStackAPI.defaults.headers.common['Authorization'] = `Token ${res.data.token}`;
      props.userHasAuthenticated(true);
      props.history.push("/");
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  return (
    <div className="Login">
      <a href="https://orgstack.io">
        <img src={logo} height="70px" />
      </a>
      <Panel>
        <h3>Log in to your account</h3>
        <br />
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              autoFocus
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={password}
              onChange={e => setPassword(e.target.value)}
              type="password"
            />
          </FormGroup>
          <br />
          <LoaderButton
            block
            type="submit"
            bsSize="large"
            bsStyle="primary"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Login
          </LoaderButton>
        </form>
        <br />
        <div id="sign-up-wrapper">
          <span>
            Don't have an account yet?&nbsp;
            <a href="https://orgstack.io/#contact" target="blank">Sign up</a>.
          </span>
        </div>
      </Panel>
    </div>
  );
}
