import React, { useRef, useState, useEffect } from "react";
import { Label, PageHeader, Tabs, Tab, Badge, Grid, Row, Col, Button, Panel, Tooltip, OverlayTrigger, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import OrgStackAPI from "../OrgStackAPI";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./DataSource.css";


export default function DataSource(props) {
  const [data_source, setDataSource] = useState(null);
  const [name, setName] = useState("");
  const [version, setVersion] = useState("");
  const [description, setDescription] = useState("");
  const [builds, setBuilds] = useState([]);
  const [defined_in, setDefinedIn] = useState(null);
  const [build_sequence, setBuildSequence] = useState(null);
  const [subscriber_count, setSubscriberCount] = useState(0);
  const [data_type, setDataType] = useState("");
  const [example_data_url, setExampleDataURL] = useState("");
  const [created, setCreated] = useState("");
  const [updated, setUpdated] = useState("");
  const [repo_name, setRepoName] = useState("");

  const [data_source_versions, setDataSourceVersions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadDataSource() {
      return OrgStackAPI.get(`/v1/data_sources/${props.match.params.id}`);
    }

    async function loadDataSourceVersions(name) {
      const query_string = new URLSearchParams([["name", name]]).toString();
      return OrgStackAPI.get(`/v1/data_sources/?${query_string}`);
    }

    async function loadBuilds(data_source_id) {
      const query_string = new URLSearchParams([["data_source", data_source_id]]).toString();
      return OrgStackAPI.get(`/v1/builds/?${query_string}`);
    }

    async function onLoad() {
      try {
        const res = await loadDataSource();
        setDataSource(res.data);

        const { name, version, description, data_schema, build_sequence, defined_in, subscriber_count, created, updated } = res.data;

        setName(name);
        setVersion(version);
        setDescription(description);
        setSubscriberCount(subscriber_count);
        setBuildSequence(build_sequence);
        setSubscriberCount(subscriber_count);
        setCreated(created);
        setUpdated(updated);

        if (data_schema !== null) {
          setDataType(data_schema.data_type);
          setExampleDataURL(data_schema.example_data_file);
        }

        if (defined_in !== null) {
          setDefinedIn(defined_in);
          setRepoName(defined_in.repo_name);
        }

        const data_source_versions_res = await loadDataSourceVersions(name);
        setDataSourceVersions(data_source_versions_res.data.results);

        const builds_res = await loadBuilds(res.data.id);
        setBuilds(builds_res.data.results);

        setIsLoading(false);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function renderDataSourceVersions() {
    const rendered_data_source_versions = data_source_versions.map((data_source_version, i) => (
      <li>{`${data_source_version.name} ${data_source_version.version}`}</li>
    ));

    return (
      <ul>{rendered_data_source_versions}</ul>
    );
  }

  function renderBuilds() {
    const rendered_builds = builds.map((build, i) => (
      <Alert bsStyle={build.status === "pass" ? "success" : "danger"}>
        <h5>{moment(build.created).fromNow()}</h5>
        <p>{build.message}</p>
      </Alert>
    ));

    return (
      builds.length > 0
      ? rendered_builds
      : <h5>No builds yet</h5>
    );
  }

  function renderBuildInfo() {
    return (
      <Col xs={12} md={4}>
        {
          builds.length > 0
          ? <>
            <h4>
              Last Build
            </h4>
              <Alert bsStyle={builds[0].status === "pass" ? "success" : "danger"}>
                <strong>{moment(builds[0].created).fromNow()}</strong>
              </Alert>
          </>
          : <></>
        }
        <h4>
          Install&nbsp;&nbsp;
          <OverlayTrigger placement="top" overlay={install_tooltip}>
            {info_icon}
          </OverlayTrigger>
        </h4>
        <Panel>
          <Panel.Body>
            <code>{`{"name": "${name}", "version": "${version}"}`}</code>
          </Panel.Body>
        </Panel>
        <h4>Build Steps</h4>
        <Panel>
          <Panel.Body>
            <code>{build_sequence.join(" ")}</code>
          </Panel.Body>
        </Panel>
      </Col>
    );
  }

  function renderConfigureInfo() {
    return (
      <Col xs={12} md={4}>
        <h4>
          Configure this data source&nbsp;&nbsp;
          <OverlayTrigger placement="top" overlay={configure_tooltip}>
            {info_icon}
          </OverlayTrigger>
        </h4>
        <Alert bsStyle="warning">
          <code>
            {`{"name": "${name}", "version": "${version}", "build_sequence": ["python", "build.py"]}`}
          </code>
        </Alert>
      </Col>
    );
  }

  const data_type_displays = {
    json: "JSON",
    csv: "CSV",
    xml: "XML"
  }

  const dot_separator = (
    <span>&nbsp;&nbsp;&nbsp;&middot;&nbsp;&nbsp;&nbsp;</span>
  );

  const pencil_icon = (
    <svg className="bi bi-pencil" width="0.6em" height="0.6em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M11.293 1.293a1 1 0 011.414 0l2 2a1 1 0 010 1.414l-9 9a1 1 0 01-.39.242l-3 1a1 1 0 01-1.266-1.265l1-3a1 1 0 01.242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z" clip-rule="evenodd"/>
      <path fill-rule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 00.5.5H4v.5a.5.5 0 00.5.5H5v.5a.5.5 0 00.5.5H6v-1.5a.5.5 0 00-.5-.5H5v-.5a.5.5 0 00-.5-.5H3z" clip-rule="evenodd"/>
    </svg>
  )

  const info_icon = (
    <svg class="bi bi-info-circle-fill" width="0.7em" height="0.7em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M8 16A8 8 0 108 0a8 8 0 000 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"/>
    </svg>
  );

  const install_tooltip = (
    <Tooltip id="install-tooltip">
      Add this dependency to your <code>datadep.json</code> file in the <b>consuming</b> section
    </Tooltip>
  );

  const configure_tooltip = (
    <Tooltip id="configure-tooltip">
      Add this configuration to a producing repository's <code>datadep.json</code> file in the <b>producing</b> section
    </Tooltip>
  );

  return (
    !isLoading &&
    <div className="DataSource">

      <PageHeader>
        {name}&nbsp;&nbsp;&nbsp;
        <Link to={`/data_sources/${data_source.id}/edit`}>
          {pencil_icon}
        </Link>
        <br />
        <h4>
          <Label>{version}</Label>{dot_separator}
          <code>{data_type}</code>{dot_separator}
          Published {moment(created).fromNow()}
        </h4>
      </PageHeader>

      <Grid id="datasource-detail-grid" fluid>
        <Row>
          <Col id="tab-column" xs={12} md={8}>
            <Tabs animation={false} defaultActiveKey={0} id="uncontrolled-tab-example">
              <Tab eventKey={0} title={<>Builds&nbsp;&nbsp;<Badge>{builds.length}</Badge></>}>
                <p>{renderBuilds()}</p>
              </Tab>
              <Tab eventKey={1} title="Description">
                <p>{description}</p>
              </Tab>
              <Tab eventKey={2} title={<>Subscribers&nbsp;&nbsp;<Badge>{subscriber_count}</Badge></>}>
                {subscriber_count} Subscribers
              </Tab>
              <Tab eventKey={3} title={<>Versions&nbsp;&nbsp;<Badge>{data_source_versions.length}</Badge></>}>
                {renderDataSourceVersions()}
              </Tab>
              <Tab eventKey={4} title="Data">
                <a href={example_data_url}>Link</a>
              </Tab>
            </Tabs>
          </Col>
          {
            defined_in == null
            ? renderConfigureInfo()
            : renderBuildInfo()
          }

        </Row>
      </Grid>

    </div>
  );
}
