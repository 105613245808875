const local = {
  api: {
    URL: "http://127.0.0.1:8000/"
  }
};

const dev = {
  api: {
    URL: "https://api-dev.orgstack.io/"
  }
};

const test = {
  api: {
    URL: "https://api-test.orgstack.io/"
  }
};

const prod = {
  api: {
    URL: "https://api.orgstack.io/"
  }
};

const environments = {
  local,
  dev,
  test,
  prod
};

const stage = process.env.REACT_APP_STAGE;
const environment = environments[stage];

export default { environment };
