import React, { useRef, useState } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import OrgStackAPI from "../OrgStackAPI";
import "./NewDataSource.css";


export default function NewDataSource(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [version, setVersion] = useState("");
  const [description, setDescription] = useState("");
  const [data_type, setDataType] = useState("json");
  const example_data_file = useRef(null);

  function validateForm() {
    return name.length > 0 && version.length > 0 && description.length > 0;
  }

  function handleFileChange(event) {
    example_data_file.current = event.target.files[0];
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setIsLoading(true);

      const teams_res = await OrgStackAPI.get('/v1/teams/');
      const team = teams_res.data.results[0].url;

      let form_data = new FormData();
      form_data.append('name', name);
      form_data.append('version', version);
      form_data.append('description', description);
      form_data.append('team', team);
      form_data.append('data_type', data_type);
      form_data.append('example_data_file', example_data_file.current);

      const data_sources_res = await OrgStackAPI.post('/v1/data_sources/', form_data);

      const redirect_url = `/data_sources/${data_sources_res.data.id}`;
      props.history.push(redirect_url);
    } catch (e) {
      alert(e);
      console.log(e.response.data);
      setIsLoading(false);
    }

  }

  return (
    <div className="NewDataSource">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="name">
          <ControlLabel>Name</ControlLabel>
          <FormControl onChange={e => setName(e.target.value)} type="text" />
        </FormGroup>

        <FormGroup controlId="version">
          <ControlLabel>Version</ControlLabel>
          <FormControl onChange={e => setVersion(e.target.value)} type="text" />
        </FormGroup>

        <FormGroup controlId="description">
          <ControlLabel>Description</ControlLabel>
          <FormControl onChange={e => setDescription(e.target.value)} componentClass="textarea" />
        </FormGroup>

        <FormGroup controlId="data_type">
          <ControlLabel>Data Type</ControlLabel>
          <FormControl onChange={e => setDataType(e.target.value)} componentClass="select">
            <option value="json">JSON</option>
            <option value="csv">CSV</option>
            <option value="xml">XML</option>
          </FormControl>
        </FormGroup>

        <FormGroup controlId="example_data_file">
          <ControlLabel>Example Data File</ControlLabel>
          <FormControl onChange={handleFileChange} type="file" />
        </FormGroup>

        <LoaderButton
          block
          type="submit"
          bsSize="large"
          bsStyle="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </form>
    </div>
  );
}
